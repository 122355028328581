var PATTERN_ENTRY = /([^\.]+?)(?=:before*{content:)(:before{content:.+?(?=}|\)|,))/g;
var PATTERN_KEY = /([^\.]+?)(?=:before{content:)/g;
var PATTERN_VALUE = /\\(.*)\'/g;

var localData;
var remoteData;

var name;
var code;
var classname;
var packagename;
var pomname;
var versionname;
var versionnameres;
var versioncode;
var author;
var url;
var description;
var license;
var licenseurl;

// The recommended way from within the init configuration:
Dropzone.options.font = {
  init: function() {
		var drop = this;
    this.on("addedfile", function(file) {
			//window.fileTTF = file;
			if(endsWith(file.name, ".ttf")) {
				//if file end ttf
				window.fileTTF = file;
			} else if(endsWith(file.name, ".css")) {
				//if file end .css
				window.fileMapping = file;
			} else if(endsWith(file.name, "codepoints")) {
				//if file end .css
				window.codepointsMapping = file;
			} else {
				alert("Only the icon font (.ttf) and mapping file (.css, codepoints) is allowed");
				drop.removeFile(file);
			}
		});
  },
	acceptedFiles:".ttf,.css",
	previewTemplate:'<div class="dz-preview dz-file-preview"><div class="dz-details"><div class="dz-filename"><span data-dz-name></span></div><div class="dz-size" data-dz-size></div></div></div>'
};

$('#processFont').click(function(e){
	processFont();
});

$('#name').on('keyup', function(e){
  var name = $('#name').val();
  $('#classname').val(replaceAll(name, " ", ""));
  $('#packagename').val(replaceAll(name, " ", "_").toLowerCase());
  $('#pomname').val(replaceAll(name, " ", "-").toLowerCase());
});

function processFont() {
	//precheck!!
	loadAndProcessLocalFiles();
	loadAndProcessRemoteFiles();
}

function loadAndProcessLocalFiles() {
	if(isEmpty(window.fileTTF)) {
    alert("one file is missing!");
		return;
  } else if (isEmpty(window.fileMapping) && isEmpty(window.codepointsMapping)) {
		alert("one file is missing!");
		return;
	}

	var worker = new Worker('scripts/worker.js');
  worker.onmessage = function(e) {
    console.log(e.data); // e.data should be an array of ArrayBuffers.
		localData = e.data;
		zipIt();
  };
  worker.onerror = function(e) {
		console.log(e);
		alert("one file is missing!");
  };
  worker.postMessage([
		{name: 'mapping', file: window.fileMapping},
  		{name: 'codepoints', file: window.codepointsMapping},
		{name: 'ttf', file: window.fileTTF}
	]);
}

function loadAndProcessRemoteFiles() {
	var worker2 = new Worker('scripts/downloader.js');
  worker2.onmessage = function(e) {
    console.log(e.data);
		remoteData = e.data;
		zipIt();
  };
  worker2.postMessage([
		{fileName: 'AndroidManifest.xml', url: '../assets/AndroidManifest.xml', type: 'plain/text'},
		{fileName: 'Base.kt', url: '../assets/Base.kt', type: 'plain/text'},
		{fileName: 'build.gradle', url: '../assets/build.gradle', type: 'plain/text'},
		{fileName: 'consumer-proguard-rules.pro', url: '../assets/consumer-proguard-rules.pro', type: 'plain/text'},
		{fileName: 'gradle.properties', url: '../assets/gradle.properties', type: 'plain/text'},
		{fileName: 'font_addon.xml', url: '../assets/font_addon.xml', type: 'plain/text'},
		{fileName: 'font_description.xml', url: '../assets/font_description.xml', type: 'plain/text'}
	]);
}

function processMappings(rawMapping, mappingType, code) {
  var result = {};

  if(mappingType == 1) {
    var map = prepareCodepointMapping(rawMapping, code);
  } else {
    var map = prepareCSSMapping(rawMapping);
  }

  var match = PATTERN_ENTRY.exec(map);
  while (match != null) {
      var entry = match[0];

      //get the key and value strings from the css regex match
      var key = PATTERN_KEY.exec(entry)[0];
      var value = PATTERN_VALUE.exec(entry)[1];

      //clean up and define the correct key, value definitions
      key = key.replace(/-/g, '_');
      key = code + key.substring(key.indexOf("_"), key.length);
      value = value.replace(/\\e/g, '\\ue');

      //build a single mapping entry using the key and value
      //we have to make sure that the value is a valid 4 position character
      if(value.length == 2) {
        value = "00" + value;
      } else if(value.length == 3) {
        value = "0" + value;
      }
      var singleMapping = key + "('\\u" + value + "')";
      //store in a map to eliminate duplicates
      result[singleMapping] = singleMapping;

      //continue with the next match, reset the key and value regex
      match = PATTERN_ENTRY.exec(map);
      PATTERN_KEY.lastIndex = 0;
      PATTERN_VALUE.lastIndex = 0;
  }

  //build our resultStr
  var resultStr = "";
  var delimiter = "";
  for (var line in result) {
    if (result.hasOwnProperty(line)) {
      resultStr = resultStr + delimiter + line;
      delimiter = ",\n\t\t";
    }
  }

  return resultStr;
}

function prepareCSSMapping(map) {
  map = replaceAll(map, " ", "");
  map = replaceAll(map, "\r", "");
  map = replaceAll(map, "\n", "");
  map = replaceAll(map, "\t", "");
  map = replaceAll(map, "\"", "'");
  map = replaceAll(map, "\\'\\*", "\\*");
  map = replaceAll(map, "\\*\\'", "\\*");
  map = replaceAll(map, "}.", "}\n.");
  map = replaceAll(map, "-", "_");
  map = replaceAll(map, "';}", "'),");
  return map;
}

function prepareCodepointMapping(map, code) {
  //.zmdi_book_image:before{content:'\f11b'),
  map = replaceAll(map, "\n", "'),\n." + code + "_");
  map = replaceAll(map, " ", ":before{content:'\\");
  map = code + "_" + map;
  return map;
}

function zipIt() {
	//make sure both workers finished
	if(isEmpty(localData) || isEmpty(remoteData)) {
		return;
	}
	if(readInputValues() == -1) {
		return;
	}

	//todo get the files
	var zip = new JSZip();

	//structure of zip file
	// | build.gradle
	// | consumer-proguard-rules.pro
	// | gradle.properties
	// |
	// | src/main
	//      | AndroidManifest.xml
	//      |
	//      | assets
	//					| fonts
	//							| font.ttf
	//      | java
	//					| com.mikepenz.base_typeface_library
	//							| Base.java
	//      | res
	//					| values
	//							| font_addon.xml

	var root = zip.folder(name);
	//add the build.gradle
	root.file("build.gradle", replacePlaceHolders(remoteData["build.gradle"]));
	//add the consumer-proguard-rules
	root.file("consumer-proguard-rules.pro", replacePlaceHolders(remoteData["consumer-proguard-rules.pro"]));
	//add the gradle.properties
	root.file("gradle.properties", replacePlaceHolders(remoteData["gradle.properties"]));

	//add more folder hirachy
	var main = root.folder("src/main");
	//add the AndroidManifest.xml
	main.file("AndroidManifest.xml", replacePlaceHolders(remoteData["AndroidManifest.xml"]));

	var fonts = main.folder("res/font");
	//add the font
	fonts.file(pomname + "_font_v" + versionnameres + ".ttf", localData["ttf"]);

	var src = main.folder("java/com/mikepenz/iconics/typeface/library/" + packagename);
	//add the java file
	var kotlinClass = replacePlaceHolders(remoteData["Base.kt"]);

	if(localData.hasOwnProperty("mapping")) {
		var mappings = processMappings(arraybuffer2string(localData["mapping"]), 0, code);
	} else if(localData.hasOwnProperty("codepoints")) {
		var mappings = processMappings(arraybuffer2string(localData["codepoints"]), 1, code);
	}

  	var kotlinClass = replaceAll(kotlinClass, "{{{fonticonmapping}}}", mappings);
	src.file(classname + ".kt", kotlinClass);

	var res = main.folder("res/values");
	//add the font_addon.xml ressource
	res.file("font_addon.xml", replacePlaceHolders(remoteData["font_addon.xml"]));
	//add the font_description.xml ressource
	res.file("font_description.xml", replacePlaceHolders(remoteData["font_description.xml"]));

	// create and download zip
	var content = zip.generate({type:"blob"});
	saveAs(content, name);
}

//HELPERS ;D
function readInputValues() {
	//get all the infos :D
	name = $('#name').val();
	code = $('#code').val();
	classname = $('#classname').val();
	packagename = $('#packagename').val();
	pomname = $('#pomname').val();
	versionname = $('#versionname').val();
	versionnameres = replaceAll(versionname, "\\.", "_");
	versioncode = $('#versioncode').val();
	author = $('#author').val();
	url = $('#url').val();
	description = $('#description').val();
	license = $('#license').val();
	licenseurl = $('#licenseurl').val();
	//TODO CHECK IF ALL SET!!!

	return 0;
}

function replacePlaceHolders(inputVal) {
	inputVal = replaceAll(inputVal, "{{{fontname}}}", name);
	inputVal = replaceAll(inputVal, "{{{fontcode}}}", code);
	inputVal = replaceAll(inputVal, "{{{fontclassname}}}", classname);
	inputVal = replaceAll(inputVal, "{{{fontpackagename}}}", packagename);
	inputVal = replaceAll(inputVal, "{{{fontpomname}}}", pomname);
	inputVal = replaceAll(inputVal, "{{{fontversionname}}}", versionname);
	inputVal = replaceAll(inputVal, "{{{fontversionnameres}}}", versionnameres);
	inputVal = replaceAll(inputVal, "{{{fontversioncode}}}", versioncode);
	inputVal = replaceAll(inputVal, "{{{fontauthor}}}", author);
	inputVal = replaceAll(inputVal, "{{{fonturl}}}", url);
	inputVal = replaceAll(inputVal, "{{{fontdescription}}}", description);
	inputVal = replaceAll(inputVal, "{{{fontlicense}}}", license);
	inputVal = replaceAll(inputVal, "{{{fontlicenseurl}}}", licenseurl);
	return inputVal;
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

function hash(value) {
  return (typeof value) + ' ' + (value instanceof Object ? (value.__hash || (value.__hash = ++arguments.callee.current)) : value.toString());
}

function removeDuplicateLines(str) {
  var re = /^(.*)(\r?\n\1)+$/gm;
  return str.replace(re, "$1");
}

// Speed up calls to hasOwnProperty
var hasOwnProperty = Object.prototype.hasOwnProperty;
function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) return true;
    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;
    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
}

 function arraybuffer2string(buf) {
   var dataView = new DataView(buf);
   var decoder = new TextDecoder("utf-8");
   return decoder.decode(dataView);
 }
